import { Box } from "@chakra-ui/react";
import React from "react";

import { IProps } from "./types";

export const MainBodyWrapper: React.FC<IProps> = ({ children }) => (
  <Box minHeight="100vh">{children}</Box>
);

export default MainBodyWrapper;
