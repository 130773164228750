import dynamic from "next/dynamic";

export const AddNewTile = dynamic(() =>
  import("./AddNewTile").then((mod) => mod.AddNewTile)
);
export const Address = dynamic(() =>
  import("./Address").then((mod) => mod.Address)
);
export const Attribute = dynamic(() =>
  import("./Attribute").then((mod) => mod.Attribute)
);
export const Button = dynamic(() =>
  import("./Button").then((mod) => mod.Button)
);
export const ButtonBoxLink = dynamic(() =>
  import("./ButtonBoxLink").then((mod) => mod.ButtonBoxLink)
);
export const ButtonLink = dynamic(() =>
  import("./ButtonLink").then((mod) => mod.ButtonLink)
);
export const CarouselButtons = dynamic(() =>
  import("./CarouselButtons").then((mod) => mod.CarouselButtons)
);
export const CarouselNavButton = dynamic(() =>
  import("./CarouselNavButton").then((mod) => mod.CarouselNavButton)
);
export const CartCostsSummary = dynamic(() =>
  import("./CartCostsSummary").then((mod) => mod.CartCostsSummary)
);
export const CartFooter = dynamic(() =>
  import("./CartFooter").then((mod) => mod.CartFooter)
);
export const CartHeader = dynamic(() =>
  import("./CartHeader").then((mod) => mod.CartHeader)
);
export const Checkbox = dynamic(() =>
  import("./Checkbox").then((mod) => mod.Checkbox)
);
export const Chip = dynamic(() => import("./Chip").then((mod) => mod.Chip));
export const CustomLink = dynamic(() =>
  import("./CustomLink").then((mod) => mod.CustomLink)
);
export const DropdownMenu = dynamic(() =>
  import("./DropdownMenu").then((mod) => mod.DropdownMenu)
);
export const DropdownSelect = dynamic(() =>
  import("./DropdownSelect").then((mod) => mod.DropdownSelect)
);
export const EditProductLink = dynamic(() =>
  import("./EditProductLink").then((mod) => mod.EditProductLink)
);
export const ErrorMessage = dynamic(() =>
  import("./ErrorMessage").then((mod) => mod.ErrorMessage)
);
export const FeaturedProductInfo = dynamic(() =>
  import("./FeaturedProductInfo").then((mod) => mod.FeaturedProductInfo)
);
export const FilterSectionWrapper = dynamic(() =>
  import("./FilterSectionWrapper").then((mod) => mod.FilterSectionWrapper)
);
export const Icon = dynamic(() => import("./Icon").then((mod) => mod.Icon));
export const IconButton = dynamic(() =>
  import("./IconButton").then((mod) => mod.IconButton)
);
export const Input = dynamic(() => import("./Input").then((mod) => mod.Input));
export const InputLabel = dynamic(() =>
  import("./InputLabel").then((mod) => mod.InputLabel)
);
export const Label = dynamic(() => import("./Label").then((mod) => mod.Label));
export const LogoLink = dynamic(() =>
  import("./LogoLink").then((mod) => mod.LogoLink)
);
export const MainMenuBanner = dynamic(() =>
  import("./MainMenu/MainMenuBanner").then((mod) => mod.MainMenuBanner)
);
export const MainMenuLeft = dynamic(() =>
  import("./MainMenu/MainMenuLeft").then((mod) => mod.MainMenuLeft)
);
export const MainMenuRight = dynamic(() =>
  import("./MainMenu/MainMenuRight").then((mod) => mod.MainMenuRight)
);
export const MainMenuUserTools = dynamic(() =>
  import("./MainMenu/MainMenuUserTools").then((mod) => mod.MainMenuUserTools)
);
export const MenuIcon = dynamic(() =>
  import("./MainMenu/MenuIcon").then((mod) => mod.MenuIcon)
);
export const Message = dynamic(() =>
  import("./Message").then((mod) => mod.Message)
);
export const MobileNavArrowIcon = dynamic(() =>
  import("./MobileNavArrowIcon").then((mod) => mod.MobileNavArrowIcon)
);
export const MobileNavLoginPanel = dynamic(() =>
  import("./MobileNavLoginPanel").then((mod) => mod.MobileNavLoginPanel)
);
export const MobileNavSearch = dynamic(() =>
  import("./MobileNavSearch").then((mod) => mod.MobileNavSearch)
);
export const NavItem = dynamic(() =>
  import("./NavItem").then((mod) => mod.NavItem)
);
export const NavMenuItem = dynamic(() =>
  import("./NavMenuItem").then((mod) => mod.NavMenuItem)
);
export const NotificationTemplate = dynamic(() =>
  import("./NotificationTemplate").then((mod) => mod.NotificationTemplate)
);
export const Overlay = dynamic(() =>
  import("./Overlay").then((mod) => mod.Overlay)
);
export const OverlayText = dynamic(() =>
  import("./OverlayText").then((mod) => mod.OverlayText)
);
export const PlaceholderImage = dynamic(() =>
  import("./PlaceholderImage").then((mod) => mod.PlaceholderImage)
);
export const Radio = dynamic(() => import("./Radio").then((mod) => mod.Radio));
export const Rating = dynamic(() =>
  import("./Rating").then((mod) => mod.Rating)
);
export const Redirect = dynamic(() =>
  import("./Redirect").then((mod) => mod.Redirect)
);
export const Replace = dynamic(() =>
  import("./Replace").then((mod) => mod.Replace)
);
export const RichTextEditorContent = dynamic(() =>
  import("./RichTextEditorContent").then((mod) => mod.RichTextEditorContent)
);
export const Select = dynamic(() =>
  import("./Select").then((mod) => mod.Select)
);
export const StripeInputElement = dynamic(() =>
  import("./StripeInputElement").then((mod) => mod.StripeInputElement)
);
export const Tile = dynamic(() => import("./Tile").then((mod) => mod.Tile));
export const TileGrid = dynamic(() =>
  import("./TileGrid").then((mod) => mod.TileGrid)
);

export { MainBodyWrapper } from "./MainBodyWrapper";
export { Loader } from "./Loader";
export { ChipV2 } from "./ChipV2";
