import { Box } from "@chakra-ui/react";
import React from "react";

import * as S from "./styles";
import { IProps } from "./types";

export const Loader: React.FC<IProps> = ({ fullScreen }: IProps) => (
  <Box minWidth={165} minHeight={301}>
    <S.Wrapper fullScreen={!!fullScreen}>
      <S.Items>
        <span />
        <span />
        <span />
      </S.Items>
    </S.Wrapper>
  </Box>
);
