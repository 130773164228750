import classNames from "classnames";
import React from "react";
import { BsX } from "react-icons/bs";

import styles from "./index.module.css";

type ChipV2Props = {
  label: string;
  onRemove: () => void;
  size?: "sm" | "md" | "lg" | "xl";
  wrapText?: boolean;
};

export const ChipV2: React.FC<ChipV2Props> = ({
  label,
  onRemove,
  size = "md",
  wrapText = false,
}) => (
  <div className={classNames([styles.container, styles[size]])}>
    <span className={classNames([styles.label, wrapText && styles.wrapText])}>
      {label}
    </span>
    <BsX fontSize={20} cursor="pointer" onClick={onRemove} />
  </div>
);
